function removeFileElement(id, filePreview, files, fileErorr) {
  files.splice(id, 1);
  showPreviewElements(filePreview, files, fileErorr);
}

function showPreviewElements(filePreview, files, fileErorr) {
  while (filePreview.firstChild) {
    filePreview.removeChild(filePreview.firstChild);
  }

  files.forEach((file, index) => {
    const listItem = document.createElement("li");
    const p = document.createElement("p");
    const crossIcon = document.createElement("img");
    crossIcon.addEventListener("click", () =>
      removeFileElement(index, filePreview, files, fileErorr)
    );
    p.textContent = file.name;
    listItem.appendChild(p);
    listItem.appendChild(crossIcon);
    filePreview.appendChild(listItem);
  });

  if (files[0]?.size > 24000000) {
    fileErorr.textContent = "Максимум 24 мб";
    fileErorr.style.display = "inline-block";
  } else {
    fileErorr.style.display = "none";
  }
}

function updateImageDisplay(filePreview, fileInput, files, fileErorr) {
  return function () {
    while (filePreview.firstChild) {
      filePreview.removeChild(filePreview.firstChild);
    }

    let uploadedFiles = fileInput.files;

    files.length = 0;
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (files.every((e) => e.name !== uploadedFiles[i].name)) {
        files.push(uploadedFiles[i]);
      }
    }

    showPreviewElements(filePreview, files, fileErorr);
  };
}

function formInputChecker(e) {
  switch (e.target.id) {
    case "name":
      checkField(e.target);
      break;
    case "email":
      checkField(e.target);
      break;
    case "checkbox":
      checkCheckbox(e.target);
      break;
    default:
      break;
  }
}

function showSuccess(input) {
  if (input.querySelector("input[type='checkbox']")) {
    const errorElement = input.querySelector(".field-error");
    errorElement.textContent = "";
    return;
  }

  const formField = input.parentElement;

  input.classList.remove("input-error");

  const errorElement = formField.querySelector(".field-error");
  errorElement.textContent = "";
}

function showError(input, message) {
  if (input.querySelector("input[type='checkbox']")) {
    const errorElement = input.querySelector(".field-error");
    errorElement.textContent = message;
    return;
  }

  const formField = input.parentElement;

  input.classList.add("input-error");

  const errorElement = formField.querySelector(".field-error");
  errorElement.textContent = message;
}

const checkField = (input) => {
  const value = input.value.trim();

  let isValid = false;

  if (value !== "" && value !== null) {
    isValid = true;
    showSuccess(input);
  } else {
    showError(input, "Это обязательное поле");
  }

  return isValid;
};

const resetFileInput = (e) => {
  e.target.value = "";
};

function checkCheckbox(input) {
  let isValid = false;

  const value = input.querySelector("input[type='checkbox']");

  if (!value.checked) {
    showError(input, "Это обязательное поле");
  } else {
    showSuccess(input);
    isValid = true;
  }

  return isValid;
}

function checkFilesSize(filesSize, fileErorr) {
  let isValid = false;

  if (filesSize > 24000000) {
    fileErorr.textContent = "Максимум 24 мб";
    fileErorr.style.display = "inline-block";
  } else {
    fileErorr.style.display = "none";
    isValid = true;
  }

  return isValid;
}

function sendDataToGoogleSheets(form, ...callbacks) {
  const url = form.action;
  const formData = getFormData(form);
  const data = formData.data;

  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      form.reset();
      if (callbacks.length) {
        callbacks.forEach((cb) => cb());
      }
    }
  };
  let encoded = Object.keys(data)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
    .join("&");
  xhr.send(encoded);
}

function getFormData(form) {
  var elements = form.elements;
  var honeypot;

  var fields = Object.keys(elements)
    .filter(function (k) {
      if (elements[k].name === "honeypot") {
        honeypot = elements[k].value;
        return false;
      }
      return true;
    })
    .map(function (k) {
      if (elements[k].name !== undefined) {
        return elements[k].name;
      } else if (elements[k].length > 0) {
        return elements[k].item(0).name;
      }
    })
    .filter(function (item, pos, self) {
      return self.indexOf(item) === pos && item;
    });

  var formData = {};
  fields.forEach(function (name) {
    var element = elements[name];

    formData[name] = element.value;

    if (element.length) {
      var data = [];
      for (var i = 0; i < element.length; i++) {
        var item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(", ");
    }
  });

  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses";
  formData.formGoogleSendEmail = form.dataset.email || "";

  return { data: formData, honeypot: honeypot };
}

const openModal = (e, isCollapse) => {
  e.preventDefault();

  if (e.target.tagName != "BUTTON" || isCollapse) {
    const collapseBlock = document.querySelector(".form-container-collapse");
    collapse(collapseBlock, "add");
  }

  const body = document.querySelector("body");
  const modal = body.querySelector(".modal");
  modal.classList.add("modal_visible");
  body.style.overflow = "hidden";
};

const CLOSE_TOAST_TIMER_MS = 5000;

const showToast = (toast) => {
  toast.classList.add("active");
  setTimeout(() => {
    closeToast(toast);
  }, CLOSE_TOAST_TIMER_MS);
};

const closeToast = (toast) => {
  toast.classList.remove("active");
};

const funcs = {
  toggle: "toggle",
  add: "add",
  remove: "remove",
};

function collapse(target, cmd) {
  const collapseButton = document.querySelector(".collapse-button");
  const buttonText = collapseButton.querySelector("span");
  const paragraphText =
    collapseButton.parentElement.querySelector(".extra-text");

  target.classList[funcs[cmd]]("show");
  collapseButton.querySelector("svg").classList[funcs[cmd]]("show");

  if (Array.from(target.classList).includes("show")) {
    setTimeout(() => {
      target.style.overflow = "visible";
    }, 500);
    buttonText.textContent = "Скрыть дополнительные поля";
    paragraphText.textContent = "";
  } else {
    buttonText.textContent = "Показать больше полей";
    paragraphText.textContent =
      ", чтобы сделать обсуждение максимально продуктивным";
    target.style.overflow = "hidden";
  }
}
